// HydraButton.tsx
import { Card, CardBody, CardHeader, CardFooter } from "@nextui-org/react";

export const HydraCard = (props: any) => {
  return (
    <Card
      className={`bg-primary text-background p-[1rem] rounded-[2rem] ${props.hasMaxW ? "max-w-[22rem]" : "max-w-full"} ${props.bordered ? "border-[0.2rem] border-background" : ""} overflow-hidden`}
    >
      {props.header ? <CardHeader>{props.header}</CardHeader> : <></>}
      <CardBody className="overflow-hidden">{props.children}</CardBody>
      {props.footer ? <CardFooter>{props.footer}</CardFooter> : <></>}
    </Card>
  );
};
