"use client";

// HydraButton
import { extendVariants, Button } from "@nextui-org/react";

export const HydraButton = extendVariants(Button, {
  variants: {
    // <- modify/add variants
    color: {
      white: "text-primary  font-bold bg-background scale-[0.95]",
      primary: "text-primary font-bold bg-background scale-[0.95]",
      secondary: "text-primary font-bold bg-background scale-[0.95]",
      transparent: "text-white font-bold bg-transparent scale-[0.95]",
    },
    size: {
      xs: "pl-[2rem] pr-[2rem] pb-[1.5rem] pt-[1.5rem] text-base",
      md: "pl-[2rem] pr-[2rem] pb-[1.5rem] pt-[1.5rem] text-base",
      xl: "pl-[2rem] pr-[2rem] pb-[1.5rem] pt-[1.5rem] text-base",
    },
    radius: {
      full: "rounded-full",
    },
    border: {
      primary: "border-[0.2rem] border-primary hover:border-primary",
      secondary: "border-[0.2rem] border-background hover:border-background",
      transparent:
        "border-[0.2rem] border-transparent hover:border-transparent",
    },
    hover: {
      full: "hover:scale-[1] hover:bg-primary hover:text-background data-[hover=true]:opacity-100  data-[focus=true]:opacity-100 hover:opacity-100",
      transparent:
        "hover:underline hover:text-background hover:bg-transparent data-[hover=true]:opacity-100  data-[focus=true]:opacity-100 hover:opacity-100",
    },
  },
  defaultVariants: {
    // <- modify/add default variants
    color: "primary",
    size: "md",
    radius: "full",
    border: "primary",
    hover: "full",
  },
});
