const BulbIcon = ({
  fill = undefined,
  filled = false,
  size = undefined,
  height = undefined,
  width = undefined,
  label = "BulbIcon",
  ...props
}) => {
  return (
    <svg
      fill={filled ? fill : "none"}
      {...props}
      viewBox="0 0 64 64"
      focusable="false"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        stroke="#FDF9ED"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M52.571 22.857a20.571 20.571 0 10-29.714 18.286V48a2.285 2.285 0 002.286 2.286h13.714A2.286 2.286 0 0041.143 48v-6.857A20.48 20.48 0 0052.57 22.857v0zM22.857 61.714h18.286"
      ></path>
    </svg>
  );
};

export default BulbIcon;
