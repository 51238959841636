const GoogleIcon = ({
  fill = undefined,
  filled = false,
  size = undefined,
  height = undefined,
  width = undefined,
  label = "GoogleIcon",
  ...props
}) => {
  return (
    <svg
      fill={filled ? fill : "none"}
      {...props}
      viewBox="0 0 313 103"
      focusable="false"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g fill="#6A488E" clipPath="url(#clip0_520_1773)">
        <path d="M133.59 54.078c0 14.784-11.565 25.678-25.759 25.678s-25.76-10.894-25.76-25.678 11.566-25.679 25.76-25.679 25.759 10.79 25.759 25.679zm-11.276 0c0-9.239-6.703-15.56-14.483-15.56s-14.483 6.321-14.483 15.56c0 9.238 6.703 15.56 14.483 15.56s14.483-6.426 14.483-15.56zM189.161 54.078c0 14.784-11.565 25.678-25.759 25.678s-25.76-10.894-25.76-25.678 11.566-25.679 25.76-25.679 25.759 10.79 25.759 25.679zm-11.276 0c0-9.239-6.703-15.56-14.483-15.56s-14.483 6.321-14.483 15.56c0 9.238 6.703 15.56 14.483 15.56s14.483-6.426 14.483-15.56zM242.417 29.95v46.102c0 18.963-11.184 26.708-24.405 26.708-12.446 0-19.936-8.324-22.761-15.131l9.817-4.087c1.749 4.18 6.032 9.111 12.932 9.111 8.463 0 13.708-5.221 13.708-15.05V73.91h-.394c-2.524 3.114-7.386 5.835-13.522 5.835-12.839 0-24.602-11.184-24.602-25.575 0-14.39 11.763-25.77 24.602-25.77 6.124 0 10.987 2.72 13.522 5.742h.394v-4.18h10.709v-.011zm-9.91 24.22c0-9.041-6.032-15.652-13.708-15.652s-14.298 6.61-14.298 15.652c0 9.042 6.518 15.468 14.298 15.468s13.708-6.518 13.708-15.468zM260.072 2.929V78.18h-10.998V2.93h10.998zM302.931 62.53l8.753 5.834c-2.825 4.18-9.633 11.38-21.395 11.38-14.587 0-25.482-11.276-25.482-25.678 0-15.27 10.987-25.678 24.22-25.678 13.233 0 19.843 10.604 21.974 16.335l1.169 2.918-34.327 14.217c2.628 5.152 6.715 7.78 12.446 7.78s9.725-2.825 12.642-7.109zm-26.94-9.24l22.946-9.528c-1.262-3.207-5.059-5.441-9.528-5.441-5.731 0-13.707 5.06-13.418 14.97zM40.44 47.397V36.503H77.15c.359 1.899.544 4.145.544 6.576 0 8.174-2.234 18.28-9.435 25.482-7.005 7.293-15.954 11.183-27.809 11.183C18.477 79.744 0 61.846 0 39.872S18.477 0 40.451 0c12.156 0 20.816 4.77 27.322 10.987l-7.687 7.687c-4.666-4.376-10.987-7.78-19.646-7.78-16.047 0-28.596 12.932-28.596 28.978S24.394 68.85 40.44 68.85c10.407 0 16.335-4.18 20.132-7.977 3.08-3.08 5.106-7.479 5.905-13.487H40.439v.011z"></path>
      </g>
      <defs>
        <clipPath id="clip0_520_1773">
          <path fill="#fff" d="M0 0H312.17V102.76H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleIcon;
