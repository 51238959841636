const DeliveryHeroIcon = ({
  fill = undefined,
  filled = false,
  size = undefined,
  height = undefined,
  width = undefined,
  label = "DeliveryHeroIcon",
  ...props
}) => {
  return (
    <svg
      fill={filled ? fill : "none"}
      {...props}
      viewBox="0 0 691 117"
      focusable="false"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M690.753 0H0V116.907H690.753V0Z" />
      <path
        d="M128.283 54.5477L113.207 60.7151L112.796 60.9892L109.095 77.7098C108.821 78.258 108.136 78.3951 107.587 77.9839L96.6232 65.1008L35.2229 91.2782C34.8117 91.5523 34.4006 90.867 34.8117 90.5929L87.9887 50.8472L81.2731 35.4971C80.999 34.8119 81.5472 33.9895 82.3695 34.2637L98.679 38.2382L111.288 26.9998C111.836 26.5886 112.521 26.8627 112.658 27.548L113.892 44.4057L128.42 52.903C129.105 53.4512 128.968 54.2735 128.283 54.5477ZM120.882 6.30462C101.968 -1.0963 81.2731 4.52291 68.39 18.6395L18.5023 72.0906C17.817 72.7758 18.0911 73.7352 18.9135 73.8723L32.2077 74.6946C33.3042 74.6946 33.4412 75.654 32.893 76.3393L0.411162 111.425C-0.137054 111.973 0.548216 112.933 1.37054 112.796L47.6948 98.1309C48.6542 97.7197 49.4765 98.6791 49.0654 99.5014L42.8979 110.466C42.6238 111.151 43.172 111.973 43.9944 111.836L111.014 96.8974C127.049 94.4304 141.303 83.7402 147.607 67.5678C157.064 43.1722 145.14 15.7613 120.882 6.30462Z"
        fill="#6A488E"
      />
      <path
        d="M300.286 87.7144H285.073L296.996 31.7963L313.717 25.6289L300.286 87.7144Z"
        fill="#6A488E"
      />
      <path
        d="M267.53 55.6433C263.829 55.6433 261.773 58.6585 260.951 62.2219C268.215 62.2219 270.408 59.892 270.408 58.1103C270.408 56.7398 269.174 55.6433 267.53 55.6433ZM259.306 71.9528C259.169 72.3639 259.169 73.0492 259.169 73.4604C259.169 75.9273 260.814 76.7497 265.337 76.7497C269.448 76.7497 275.068 75.7903 278.494 74.5568V85.9323C274.245 87.4399 267.53 88.3993 262.185 88.3993C249.576 88.3993 243.545 84.9729 243.545 73.5974C243.545 62.2219 248.753 44.9531 268.763 44.9531C281.509 44.9531 285.347 50.7094 285.347 56.7398C285.21 64.5519 278.494 71.2675 259.306 71.9528Z"
        fill="#6A488E"
      />
      <path
        d="M328.108 42.8977C322.899 42.8977 319.61 39.6084 319.61 34.9486C319.61 28.9182 323.859 25.6289 329.067 25.6289C334.275 25.6289 337.564 28.9182 337.564 33.441C337.427 39.6084 333.316 42.8977 328.108 42.8977Z"
        fill="#6A488E"
      />
      <path
        d="M317.28 46.3242H333.041L325.092 87.0293H309.194L314.813 58.522"
        fill="#6A488E"
      />
      <path
        d="M378.269 46.3242C372.787 60.0296 366.894 73.3239 359.219 87.0293H339.757C337.29 74.1462 336.194 60.4408 336.468 46.3242H352.366C352.092 52.9028 352.092 59.6185 352.503 66.1971C352.64 67.9788 352.777 69.4864 352.914 71.131H353.051C353.737 69.4864 354.559 67.8417 355.244 66.1971C357.985 59.4814 360.452 52.2175 362.371 46.3242H378.269Z"
        fill="#6A488E"
      />
      <path
        d="M399.513 55.6433C395.812 55.6433 393.756 58.6585 392.934 62.2219C400.198 62.2219 402.391 59.892 402.391 58.1103C402.254 56.7398 401.157 55.6433 399.513 55.6433ZM391.152 71.9528C391.015 72.3639 391.015 72.9122 391.015 73.4604C391.015 75.9273 392.797 76.7497 397.183 76.7497C401.294 76.7497 406.914 75.7903 410.34 74.5568V85.9323C406.091 87.4399 399.376 88.3993 394.031 88.3993C381.422 88.3993 375.391 84.9729 375.391 73.5974C375.391 62.2219 380.599 44.9531 400.609 44.9531C413.355 44.9531 417.193 50.7094 417.193 56.7398C417.193 64.5519 410.477 71.2675 391.152 71.9528Z"
        fill="#6A488E"
      />
      <path
        d="M447.756 60.714C446.522 60.4399 445.289 60.3028 443.918 60.3028C440.355 60.3028 437.614 63.8663 436.38 70.3078L433.091 87.1654H417.193L425.142 46.4604H437.066L437.477 51.9425C441.177 47.4198 444.055 45.0898 448.441 45.0898C450.497 45.0898 451.593 45.2269 452.142 45.3639L447.756 60.714Z"
        fill="#6A488E"
      />
      <path
        d="M497.369 46.3242C490.38 64.5524 484.212 77.8467 477.908 87.0293C468.862 100.461 460.913 103.613 452.827 103.613C450.36 103.613 447.893 102.928 446.659 102.379L449.126 90.5927H454.608C457.35 90.5927 458.446 89.4963 460.228 87.1663C457.212 76.202 455.705 60.852 455.842 46.4613H471.74C471.466 53.0399 471.466 59.6185 471.877 66.06C472.014 67.8417 472.151 69.6234 472.288 71.2681H472.426C473.111 69.6234 473.933 67.9788 474.618 66.1971C477.222 59.7555 479.552 53.0399 481.471 46.3242H497.369Z"
        fill="#6A488E"
      />
      <path
        d="M558.358 87.0293H541.638L545.612 66.6082H531.359L527.384 87.0293H510.664L521.217 32.7559H538.074L534.237 52.2175H548.353L552.191 32.7559H568.912L558.358 87.0293Z"
        fill="#6A488E"
      />
      <path
        d="M591.251 55.6433C587.551 55.6433 585.495 58.6585 584.673 62.2219C591.937 62.2219 594.13 59.892 594.13 58.1103C594.13 56.7398 592.896 55.6433 591.251 55.6433ZM582.891 71.9528C582.754 72.3639 582.754 72.9122 582.754 73.4604C582.754 75.9273 584.536 76.7497 588.922 76.7497C593.033 76.7497 598.789 75.7903 602.079 74.5568V85.9323C597.83 87.4399 591.114 88.3993 585.769 88.3993C573.16 88.3993 567.13 84.9729 567.13 73.5974C567.13 62.2219 572.338 44.9531 592.348 44.9531C605.094 44.9531 608.931 50.7094 608.931 56.7398C609.068 64.5519 602.216 71.2675 582.891 71.9528Z"
        fill="#6A488E"
      />
      <path
        d="M639.494 60.714C638.261 60.4399 637.027 60.3028 635.657 60.3028C632.231 60.3028 629.352 63.8663 628.119 70.3078L624.83 87.1654H608.931L616.88 46.4604H628.804L629.215 51.9425C632.916 47.4198 635.794 45.0898 640.18 45.0898C642.236 45.0898 643.332 45.2269 643.88 45.3639L639.494 60.714Z"
        fill="#6A488E"
      />
      <path
        d="M665.535 57.4251C659.23 57.4251 657.449 67.1559 657.449 71.5416C657.449 74.968 658.956 76.0644 661.834 76.0644C668.139 76.0644 669.783 66.3336 669.783 61.9478C669.783 58.5215 668.276 57.4251 665.535 57.4251ZM659.641 88.5363C647.306 88.5363 641.276 83.1912 641.276 72.6381C641.276 60.8514 647.032 44.9531 667.591 44.9531C679.925 44.9531 685.956 50.4353 685.956 60.8514C685.956 72.7751 680.199 88.5363 659.641 88.5363Z"
        fill="#6A488E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.419 74.1459H206.266L211.886 45.5016H216.271C222.85 45.5016 225.317 49.3391 225.317 54.8212C225.18 65.6485 219.287 74.1459 209.419 74.1459ZM224.221 46.598L234.774 36.456C230.662 33.989 225.043 32.8926 217.916 32.8926H197.632L187.079 87.166H208.185C232.718 87.166 242.449 70.0342 242.449 52.2172C242.449 47.5574 241.489 43.8569 239.571 40.9788L224.221 46.598Z"
        fill="#6A488E"
      />
    </svg>
  );
};

export default DeliveryHeroIcon;
