const HydraIcon = ({
  fill = undefined,
  filled = false,
  size = undefined,
  height = undefined,
  width = undefined,
  label = "HydraIcon",
  ...props
}) => {
  return (
    <svg
      fill={filled ? fill : "none"}
      {...props}
      viewBox="0 0 512 512"
      focusable="false"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M320.078 98.6371C320.078 47.2963 361.698 5.67627 413.039 5.67627C464.38 5.67627 506 47.2963 506 98.6371C506 149.978 464.38 191.598 413.039 191.598C361.698 191.598 320.078 149.978 320.078 98.6371Z"
        fill="#FDF9ED"
        fillRule="nonzero"
        opacity="1"
        stroke="#FDF9ED"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="6.048"
      />
      <path
        d="M320.078 413.363C320.078 362.022 361.698 320.402 413.039 320.402C464.38 320.402 506 362.022 506 413.363C506 464.704 464.38 506.324 413.039 506.324C361.698 506.324 320.078 464.704 320.078 413.363Z"
        fill="#FDF9ED"
        fillRule="nonzero"
        opacity="1"
        stroke="#FDF9ED"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="6.048"
      />
      <path
        d="M6 98.6371C6 47.2963 47.62 5.67627 98.9609 5.67627C150.302 5.67627 191.922 47.2963 191.922 98.6371C191.922 149.978 150.302 191.598 98.9609 191.598C47.62 191.598 6 149.978 6 98.6371Z"
        fill="#FDF9ED"
        fillRule="nonzero"
        opacity="1"
        stroke="#FDF9ED"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="6.048"
      />
    </svg>
  );
};

export default HydraIcon;
