"use client";

import { useTranslations } from "next-intl";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useState, forwardRef } from "react";

import styles from "@/components/SignUpForm/signUpForm.module.scss";

import { Textarea } from "@nextui-org/input";
import { Input } from "@nextui-org/react";
import { Chip } from "@nextui-org/react";

import { HydraCard } from "@/components/HydraCard";
import { HydraButton } from "@/components/HydraButton";

import CheckmarkIcon from "@/components/Icons/CheckmarkIcon";

interface RepoType {
  email: string;
  repo: string;
  message: string;
  robot?: string;
}

const SignUpForm = () => {
  const t = useTranslations("SignUpForm");
  const errorMessages = {
    url: "Please enter a valid GitHub URL",
    invalidBounty: "Please enter a valid bounty amount",
  };
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const github_issue_regex = new RegExp("https://github.com/[^/]+/[^/]+/", "g");
  const emailschema = z.object({
    email: z.string().email("Invalid email address"),
    repo: z.optional(
      z.string().regex(github_issue_regex, {
        message: errorMessages.url,
      })
    ),
    message: z.optional(z.string()),
  });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<RepoType>({
    resolver: zodResolver(emailschema),
    defaultValues: {},
  });

  async function onSubmit(data: RepoType) {
    try {
      // const email_response: any = await axios.post(
      //   "/api/internalnotification/",
      //   { ...data, contact_type: "repo" }
      // );
      // if (email_response.status === 200) {
      setSubmitted(true);
      // } else {
      //   setSubmitError(email_response?.message);
      // }
    } catch (error: any) {
      console.error(error);
    }
  }

  return (
    <>
      <HydraCard className={submitted && "rounded-full"}>
        {!submitted ? (
          <form
            className={styles.createBountyForm}
            onSubmit={handleSubmit(onSubmit)}
            aria-describedby="signup-for-newsletter-form"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <div style={{ display: "none" }}>
              <Input
                id="amount"
                aria-describedby={"signup-important-field"}
                type="text"
                {...register("robot")}
                placeholder="Please fill out this field"
                hidden
                classNames={{
                  label: "text-background dark:text-background",
                  input: [
                    "text-background dark:text-background",
                    "placeholder:text-background dark:placeholder:text-background",
                  ],
                }}
              />
              {errors.robot && (
                <Chip color="danger">{errors.robot.message}</Chip>
              )}
            </div>
            <Textarea
              id="message"
              label={t("message-input-label")}
              {...register("message")}
              placeholder="I am interested in..."
              variant="underlined"
              minRows={10}
              classNames={{
                label: `text-background dark:text-background group-data-[filled-within=true]:text-background dark:group-data-[filled-within=true]:text-background ${errors.message ? "text-danger group-data-[filled-within=true]:text-danger dark:group-data-[filled-within=true]:text-danger" : ""}`,
                innerWrapper: `text-background dark:text-background ${errors.message ? "text-danger dark:text-danger" : ""}`,
                inputWrapper: `text-background dark:text-background after:bg-background ${errors.message ? "text-danger after:bg-danger" : ""}`,
                input: [
                  `text-background dark:text-background group-data-[filled-within=true]:text-background dark:group-data-[filled-within=true]:text-background ${errors.message ? "text-danger group-data-[filled-within=true]:text-danger dark:group-data-[filled-within=true]:text-danger" : ""}`,
                  "w-[20rem] max-w-full",
                  `placeholder:text-background/2 dark:placeholder:text-background/2 ${errors.message ? "placeholder:text-danger/2 dark:placeholder:text-danger/2" : ""}`,
                  `group-data-[has-value=true]:text-background dark:group-data-[has-value=true]:text-background ${errors.message ? "group-data-[has-value=true]:text-danger dark:group-data-[has-value=true]:text-danger" : ""}`,
                ],
              }}
            />
            {errors.message && (
              <Chip color="danger">{errors.message.message}</Chip>
            )}
            {submitError && <Chip color="danger">{submitError}</Chip>}
            <Input
              id="email"
              aria-describedby={"signup-email-field"}
              type="email"
              {...register("email")}
              label={t("email-input-label")}
              placeholder="max.mustermann@hydraoss.io"
              variant="underlined"
              color={"danger"}
              classNames={{
                label: `text-background dark:text-background group-data-[filled-within=true]:text-background dark:group-data-[filled-within=true]:text-background ${errors.email ? "text-danger group-data-[filled-within=true]:text-danger dark:group-data-[filled-within=true]:text-danger" : ""}`,
                innerWrapper: `text-background dark:text-background ${errors.email ? "text-danger dark:text-danger" : ""}`,
                inputWrapper: `text-background dark:text-background after:bg-background ${errors.email ? "text-danger after:bg-danger" : ""}`,
                input: [
                  `text-background dark:text-background group-data-[filled-within=true]:text-background dark:group-data-[filled-within=true]:text-background ${errors.email ? "text-danger group-data-[filled-within=true]:text-danger dark:group-data-[filled-within=true]:text-danger" : ""}`,
                  "w-[20rem] max-w-full",
                  `placeholder:text-background/2 dark:placeholder:text-background/2 ${errors.email ? "placeholder:text-danger/2 dark:placeholder:text-danger/2" : ""}`,
                  `group-data-[has-value=true]:text-background dark:group-data-[has-value=true]:text-background ${errors.email ? "group-data-[has-value=true]:text-danger dark:group-data-[has-value=true]:text-danger" : ""}`,
                ],
              }}
            />
            {errors.email && <Chip color="danger">{errors.email.message}</Chip>}
            {submitError && <Chip color="danger">{submitError}</Chip>}
            <HydraButton
              type="submit"
              onClick={handleSubmit(onSubmit)}
              aria-describedby="signup-for-newsletter-button"
              autoFocus={false}
              color="secondary"
              border="secondary"
            >
              {t("button-contact")}
            </HydraButton>
          </form>
        ) : (
          <div className="w-full flex flex-col items-center justify-center">
            <CheckmarkIcon
              className="w-[4rem] h-[4rem]"
              filled
              fill={"#FDF9ED"}
            />
          </div>
        )}
      </HydraCard>
    </>
  );
};
SignUpForm.displayName = "SignUpForm";
export default SignUpForm;
