const RibbonIcon = ({
  fill = undefined,
  filled = false,
  size = undefined,
  height = undefined,
  width = undefined,
  label = "RibbonIcon",
  ...props
}) => {
  return (
    <svg
      fill={filled ? fill : "none"}
      {...props}
      viewBox="0 0 64 64"
      focusable="false"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        stroke="#FDF9ED"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M31.406 43.428c11.361 0 20.571-9.21 20.571-20.571 0-11.362-9.21-20.572-20.571-20.572-11.362 0-20.572 9.21-20.572 20.572 0 11.36 9.21 20.571 20.572 20.571z"
      ></path>
      <path
        stroke="#FDF9ED"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M31.406 31.999a9.143 9.143 0 100-18.286 9.143 9.143 0 000 18.286zM27.429 43.062l-4.023 16.914a2.331 2.331 0 01-1.189 1.509 2.469 2.469 0 01-1.966 0L5.074 54.856a2.33 2.33 0 01-.823-3.566L16 36.57M36.571 42.835l4.115 17.142a2.285 2.285 0 001.234 1.509 2.332 2.332 0 001.92 0l15.086-6.629a2.287 2.287 0 001.28-1.6 2.194 2.194 0 00-.457-1.965L47.497 35.703"
      ></path>
    </svg>
  );
};

export default RibbonIcon;
